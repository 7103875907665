@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
  
}
body {
  background: #eaefef;
  font-family: 'Raleway', sans-serif;
}

p, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
}

.wrapper {
  width: 300px;
  height: 300px;
  border: 5px solid #515151;
}
.exmpl {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exmpl img {
  height: 100%;
  width: auto;
}